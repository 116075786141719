<template>
  <div>
    <!-- sayfanın adı ve düzenleme tarihini aşağıdaki row gösteriyor -->
<!--     <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ p_WebsiteUnitData.data['0']['4']['lang'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'">
        {{ f_dateFormat(p_WebsiteUnitData.updated_at) }}
      </b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row> -->
    <!-- <div style="height: 40px;"></div> -->
    <h3 v-if="p_WebsiteUnitData && p_WebsiteUnitData.data && p_WebsiteUnitData.data['0'] && p_WebsiteUnitData.data['0']['5'] && p_WebsiteUnitData.data['0']['5']['lang']" style="text-align: center;"> {{ p_WebsiteUnitData.data['0']['5']['lang'][StoreLang] }} </h3>

    <template v-if="p_WebsiteUnitData && p_WebsiteUnitData.data && p_WebsiteUnitData.data['0'] && p_WebsiteUnitData.data['0']['7'] && p_WebsiteUnitData.data['0']['7']['list'] && p_WebsiteUnitData.data['0']['7']['list'].length > 0">
      <template v-for="(page_info, page_info_index) in p_WebsiteUnitData.data['0']['7']['list']">
        <template v-if="page_info['8'] && page_info['8'].lang && page_info['8'].lang[StoreLang]">
          <template v-if="page_info['8'].lang[StoreLang].indexOf('</') !== -1">
            <div v-html="page_info['8'].lang[StoreLang]"></div>
          </template>
          <template v-else>
            <b-row>
              <b-col sm="12" md="2" lg="2">
              </b-col>
              <b-col sm="12" md="8" lg="8">
                <h3> {{ page_info['8'].lang[StoreLang] }} </h3>
              </b-col>
              <b-col sm="12" md="2" lg="2">
              </b-col>
            </b-row>
          </template>
        </template>
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '1'">
            <div class="wrapper">
              <div class="animated fadeIn" style="min-height: 600px;">
                <b-carousel id="carousel1"
                            style="text-shadow: 1px 1px 2px #333; height: 500px;"
                            controls
                            indicators
                            background="#ababab"
                            :interval="10000"
                            img-width=""
                            :img-height="500"
                            v-model="slide"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                >
                  <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                    <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                      <template v-if="f_getImageLink(page_info_detail)">
                          <!-- Text slides with image -->
                          <b-carousel-slide
                            :caption="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang] ? page_info_detail['10']['lang'][StoreLang] : ''"
                            :text="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang] ? page_info_detail['26']['lang'][StoreLang] : ''"
                            :caption-tag="'h6'"
                          >
                          <template #img>
                            <img
                              class="d-block w-100"
                              height="500"
                              :src="f_getImageLink(page_info_detail)"
                              alt="image slot"
                            >
                          </template>
                          <p>
                              <b-row v-if="page_info_detail['11'] && page_info_detail['11']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <div v-html="page_info_detail['11']['lang'][StoreLang]"></div>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['12'] && page_info_detail['12']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <div class="code-text" v-html="page_info_detail['12']['lang'][StoreLang]"></div>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['13'] && page_info_detail['13']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <h6> <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">{{ f_createHrefLink(page_info_detail['13'].lang[StoreLang]) }}</a> </h6>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['14'] && page_info_detail['14']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <div v-html="page_info_detail['14']['lang'][StoreLang]"></div>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['15'] && page_info_detail['15']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <div v-html="page_info_detail['15']['lang'][StoreLang]"></div>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['16'] && page_info_detail['16']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <div v-html="page_info_detail['16']['lang'][StoreLang]"></div>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['17'] && page_info_detail['17']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <cite :title="page_info_detail['17']['lang'][StoreLang]"><a :href="'mailto:' + page_info_detail['17']['lang'][StoreLang] + '?Subject='" target="_top">{{ page_info_detail['17']['lang'][StoreLang] }}</a></cite>
                                </b-col>
                              </b-row>
                              <b-row v-if="page_info_detail['18'] && page_info_detail['18']['lang'][StoreLang]">
                                <b-col cols="1" style="text-align: right;">
                                </b-col>
                                <b-col cols="11">
                                  <iframe :src="page_info_detail['18']['lang'][StoreLang]" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                                </b-col>
                              </b-row>
                          </p>
                          </b-carousel-slide>
                      </template>
                    </template>
                  </template>
                </b-carousel>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 4column_textview_linkontext -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '2'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="3" style="text-align: center; padding: 10px;">
                            <h1 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                              <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </a>
                            </template>
                            <template v-else>
                              <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                            </template>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 4column_textview_linkclickfordetails -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '3'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="3" style="text-align: center; padding: 10px;">
                            <h1 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div>
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 2column_lefttextview_rightimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '4'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row style="margin-top: 10px; margin-bottom: 10px;">
                          <b-col sm="12" md="12" lg="6">
                            <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                          <b-col sm="12" md="12" lg="6">
                            <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 2columnimagecolumnsmaller_lefttextview_rightimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '14'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row style="margin-top: 10px; margin-bottom: 10px;">
                          <b-col sm="12" md="12" lg="9">
                            <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                          <b-col sm="12" md="12" lg="3">
                            <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 2column_righttextview_leftimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '5'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <template v-if="StoreDevice.isMobile">
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="6">
                              <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </b-col>
                            <b-col sm="12" md="12" lg="6">
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                            </b-col>
                          </b-row>                          
                        </template>
                        <template v-else>
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="6">
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                            </b-col>
                            <b-col sm="12" md="12" lg="6">
                              <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </b-col>
                          </b-row>
                        </template>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 2columnimagecolumnsmaller_righttextview_leftimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '15'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <template v-if="StoreDevice.isMobile">
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="9">
                              <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </b-col>
                            <b-col sm="12" md="12" lg="3">
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                            </b-col>
                          </b-row>
                        </template>
                        <template v-else>
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="3">
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                            </b-col>
                            <b-col sm="12" md="12" lg="9">
                              <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </b-col>
                          </b-row>
                        </template>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 1column_lefttextview_backgroundimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '6'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; width: 100%; padding: 30px; border-radius: 10px 10px; height: 250px;'">
                          <b-col sm="12" md="12" lg="6">
                            <h1 v-if="page_info_detail['10'] && page_info_detail['10'].lang" style="color: white;">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                          <b-col sm="12" md="12" lg="6"></b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 1column_righttextview_backgroundimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '7'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; width: 100%; padding: 30px; border-radius: 10px 10px; height: 250px;'">
                          <b-col sm="12" md="12" lg="6">
                          </b-col>
                          <b-col sm="12" md="12" lg="6">
                            <h1 v-if="page_info_detail['10'] && page_info_detail['10'].lang" style="color: white;">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 1column_centertextview_noimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '8'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row style="text-align: center; padding: 20px;">
                          <b-col sm="12" md="12" lg="1"></b-col>
                          <b-col sm="12" md="12" lg="10">
                            <h1 v-if="page_info_detail['10'] && page_info_detail['10'].lang && page_info_detail['10'].lang[StoreLang]" style="font-weight: 700; color: rgb(8, 68, 75);">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                          <b-col sm="12" md="12" lg="1"></b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 2column_righttextview_leftimageicon -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '9'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="6" style="height: 300px; padding: 10px;">
                             <b-card class="component_9">
                                <b-row>
                                  <b-col sm="12" md="12" lg="3">
                                    <div v-if="f_getImageLink(page_info_detail)" style="display: flex; align-items: center; height: 100%;">
                                      <img :src="f_getImageLink(page_info_detail)" style="height: 40px; width: 40px;">
                                    </div>
                                  </b-col>
                                  <b-col sm="12" md="12" lg="9">
                                    <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                    <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                      <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                    </template>
                                    <template v-else>
                                      <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                    </template>
                                  </template>
                                    <div>
                                      <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                        <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                          {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                        </a>
                                      </template>
                                    </div>
                                  </b-col>
                                </b-row>
                             </b-card>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 3column_belowtextview_leftimageiconofcaption -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '10'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="4" style="height: 300px; padding-bottom: 5px; margin-bottom: 10px;">
                             <b-card class="component_10">
                                <b-row>
                                  <b-col sm="12" md="12" lg="2">
                                    <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="height: 30px; width: 30px;">
                                  </b-col>
                                  <b-col sm="12" md="12" lg="10">
                                    <h3 v-if="page_info_detail['10'] && page_info_detail['10']">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12" md="12" lg="12">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                      <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                        <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                      </template>
                                      <template v-else>
                                        <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                                <b-row style="margin-top: 20px;">
                                  <b-col sm="12" md="12" lg="12">
                                    <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                      <div>
                                        <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                          {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                        </a>
                                        </div>
                                    </template>
                                  </b-col>
                                </b-row>
                             </b-card>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 3column_belowtextview_topimageofcaption -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '11'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="4" style="min-height: 300px;">
                             <div style="height: 100%; background-color: rgba(252, 252, 252, 1.0); border-radius: 20px 20px 20px 20px; box-shadow: rgb(245 245 245) 0px 0px 11px 0px; border: none; padding: 0px;">
                                <b-row style="min-height: 150px; margin: 0px;">
                                  <b-col sm="12" md="12" lg="12" style="padding: 0px;">
                                    <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="height: 100%; width: 100%; border-radius: 20px 20px 0px 0px;">
                                  </b-col>
                                </b-row>
                                <b-row style="min-height: 30px; padding: 15px;">
                                  <b-col sm="12" md="12" lg="12">
                                    <h3 v-if="page_info_detail['10'] && page_info_detail['10'].lang">{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </b-col>
                                </b-row>
                                <b-row v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]" style="min-height: 30px; padding: 15px;">
                                  <b-col sm="12" md="12" lg="12">
                                    <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                      <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                    </template>
                                    <template v-else>
                                      <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                    </template>
                                  </b-col>
                                </b-row>
                                <b-row v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]" style="margin-top: 30px; padding: 15px;">
                                  <b-col sm="12" md="12" lg="12">
                                      <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                        {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                      </a>
                                  </b-col>
                                </b-row>
                             </div>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 1column_centertextview_backgroundfullwidthimage_linkclickfordetailsastext -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '12'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="12">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; padding: 30px; height: 350px; text-align: center;'">
                          <b-col sm="12" md="12" lg="12" style="padding: 50px;">
                            <h1 style="color: white; margin: 10px;">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <div style="height: 30px;"></div>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <h4 style="color: white; margin: 10px;" v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</h4>
                              </template>
                            </template>

                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                </a>
                              </template>
                            </div>
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 1column_centertextview_backgroundfullwidthimage_linkclickfordetailsasbutton -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '13'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="12">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; padding: 30px; height: 350px; text-align: center;'">
                          <b-col sm="12" md="12" lg="12" style="padding: 50px;">
                            <h1 style="color: white;">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                            <div style="margin-top: 30px;">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                <b-button variant="primary" @click="f_goToThisUrl(page_info_detail['13'].lang[StoreLang])">
                                  <span style="margin-right: 20px;">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </span>
                                  <i class="fa fa-long-arrow-right"></i>
                                </b-button>
                              </template>
                            </div>
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- general -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '0'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8" style="background-color: white;">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                    <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                      <b-row style="margin-top: 10px; margin-bottom: 10px;">
                        <b-col cols="12" style="padding: 10px;">
                          <b-row v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                            <b-col cols="12">
                              <!-- <div v-html="page_info_detail['10']['lang'][StoreLang]" :id="page_info_index.toString() + '_' + page_info_detail_index.toString()"></div> -->
                              <div v-if="page_info_detail['15'] && page_info_detail['15']['lang'][StoreLang]" :id="page_info_detail['15']['lang'][StoreLang]"></div>

                              <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                              </template>
                              <template v-else>
                                <h6 style="font-weight: 600;"> {{ page_info_detail['10']['lang'][StoreLang] }} </h6>
                              </template>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['26'] && page_info_detail['26']['lang'] && page_info_detail['26']['lang'][StoreLang]">
                            <b-col cols="12">
                              <template v-if="page_info_detail['13'] && page_info_detail['13'].lang">
                                <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                  <div v-html="page_info_detail['26']['lang'][StoreLang]"></div>
                                </a>
                              </template>
                              <template v-else>
                                <div v-html="page_info_detail['26']['lang'][StoreLang]"></div>
                              </template>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['11'] && page_info_detail['11']['lang'][StoreLang]">
                            <b-col cols="12">
                              <div v-html="page_info_detail['11']['lang'][StoreLang]"></div>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['12'] && page_info_detail['12']['lang'][StoreLang]">
                            <b-col cols="12">
                              <div class="code-text" v-html="page_info_detail['12']['lang'][StoreLang]" contenteditable="true"></div>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['14'] && page_info_detail['14']['lang'][StoreLang]">
                            <b-col cols="12">
                              <div v-html="page_info_detail['14']['lang'][StoreLang]"></div>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['16'] && page_info_detail['16']['lang'][StoreLang]">
                            <b-col cols="12">
                              <div v-html="page_info_detail['16']['lang'][StoreLang]"></div>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['17'] && page_info_detail['17']['lang'][StoreLang]">
                            <b-col cols="12">
                              <cite :title="page_info_detail['17']['lang'][StoreLang]"><a :href="'mailto:' + page_info_detail['17']['lang'][StoreLang] + '?Subject='" target="_top">{{ page_info_detail['17']['lang'][StoreLang] }}</a></cite>
                            </b-col>
                          </b-row>
                          <b-row v-if="page_info_detail['18'] && page_info_detail['18']['lang'][StoreLang]">
                            <b-col cols="12">
                              <iframe :src="page_info_detail['18']['lang'][StoreLang]" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <template v-if="page_info_detail['21'] && page_info_detail['21']['lang'] && page_info_detail['21']['lang'][StoreLang]">
                                <template v-if="page_info_detail['28'] && page_info_detail['28']['val'] && page_info_detail['28']['val']['value']">
                                  <template v-if="page_info_detail['28']['val']['value'] === '0'">
                                    <wanalyzer-cell-calculate :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_idType="f_wanalzyerProjectIdType(page_info_detail)"></wanalyzer-cell-calculate>
                                  </template>
                                  <template v-else-if="page_info_detail['28']['val']['value'] === '1'">
                                    <template v-if="page_info_detail['32'] && page_info_detail['32']['lang'] && page_info_detail['32']['lang'][StoreLang]">
                                      <wanalyzer-stats-view :key="'1_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_statsId="page_info_detail['32']['lang'][StoreLang]"></wanalyzer-stats-view>
                                    </template>                                
                                  </template>
                                  <template v-else-if="page_info_detail['28']['val']['value'] === '2'">
                                      <wanalyzer-dataset-analyze :key="'2_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_datasetView="'yes'"></wanalyzer-dataset-analyze>
                                  </template>
                                  <template v-else-if="page_info_detail['28']['val']['value'] === '3'">
                                      <wanalyzer-dataset-analyze :key="'3_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_datasetView="'no'"></wanalyzer-dataset-analyze>
                                  </template>
                                  <template v-else-if="page_info_detail['28']['val']['value'] === '4'">
                                    <template v-if="page_info_detail['20'] && page_info_detail['20']['lang'] && page_info_detail['20']['lang'][StoreLang]">
                                      <machine-learning-query :key="'4_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_machineLearningId="page_info_detail['20']['lang'][StoreLang]"></machine-learning-query>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </b-col>
                          </b-row>
                          <b-row v-if="f_getImageLink(page_info_detail)">
                            <b-col cols="12">
                              <img :src="f_getImageLink(page_info_detail)" style="height: 250px;">
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import {
  default as MachineLearningQuery
} from "@/components/widgets/MachineLearningQuery";
import {
  default as WanalyzerCellCalculate
} from "@/components/widgets/WanalyzerCellCalculate";
import {
  default as WanalyzerDatasetAnalyze
} from "@/components/widgets/WanalyzerDatasetAnalyze";
import {
  default as WanalyzerStatsView
} from "@/components/widgets/WanalyzerStatsView";
import WanalyzerService from '@/services/wanalyzer';
import { mapGetters } from 'vuex';
export default {
  name: 'PageInfoWebsiteUnit',
  components: {
    MachineLearningResult,
    MachineLearningQuery,
    WanalyzerCellCalculate,
    WanalyzerDatasetAnalyze,
    WanalyzerStatsView,
  },
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  props: {
    p_WebsiteUnitData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null,
      d_machineLearningList: {}
    }
  },
  created: function () {
    // console.log(this.p_pageInfo);
    // if (this.p_WebsiteUnitData && this.p_WebsiteUnitData.data && this.p_WebsiteUnitData.data['0'] && this.p_WebsiteUnitData.data['0']['7'] && this.p_WebsiteUnitData.data['0']['7']['list'] && this.p_WebsiteUnitData.data['0']['7']['list'].length > 0) {
    //   for (let page_info_index in this.p_WebsiteUnitData.data['0']['7']['list']) {
    //     if (this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9'] && this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'] && this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'].length > 0) {
    //       for (let page_info_detail_index in this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list']) {
    //         let page_info_detail_item = this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'][page_info_detail_index];
    //         if (page_info_detail_item['20'] && page_info_detail_item['20'].val) {
    //           let machine_learning_id = page_info_detail_item['20'].val;
    //           this.f_wanalyzerMachinelearningDataGet(machine_learning_id);
    //         }
    //       }
    //     }
    //   }
    // }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_wanalzyerProjectIdType: function (page_info_detail) {
      let getting_analysis_output_with_column_inputs_id_type = 'no';
      try {
        if (page_info_detail['29']['val']['value'] === '0') {
          getting_analysis_output_with_column_inputs_id_type = 'no';
        } else if (page_info_detail['29']['val']['value'] === '1') {
          getting_analysis_output_with_column_inputs_id_type = 'client_id_based';
        } else if (page_info_detail['29']['val']['value'] === '2') {
          getting_analysis_output_with_column_inputs_id_type = 'based_on_wdmr_id';
        } else if (page_info_detail['29']['val']['value'] === '3') {
          getting_analysis_output_with_column_inputs_id_type = 'based_on_client_id_or_wdmr_id';
        }
      } catch(err) {}
      return getting_analysis_output_with_column_inputs_id_type;
    },
    f_getImageLink: function (page_info_detail) {
      let image_link = '';
      try {
        image_link = page_info_detail['22'] && page_info_detail['22'].lang && page_info_detail['22'].lang[this.StoreLang] ? page_info_detail['22']['lang'][this.StoreLang] : page_info_detail['19'] && page_info_detail['19'].lang && page_info_detail['19'].lang[this.StoreLang] ? page_info_detail['19']['lang'][this.StoreLang] : '';
      } catch(err) {
        console.log(err);
      }
      // console.log('image_link ', image_link);
      return image_link;
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
          return x;
        }
      } else {
        return "";
      }
    },
    f_createHrefLink: function (url) {
      let href_url = '';
      if (url.indexOf('http') !== -1) {
        href_url = url;
      } else {
        // console.log(window.location);
        if (url) {
          if (url[0] === '/') {
            href_url = window.location.origin + '/#' + url;
          } else {
            href_url = window.location.origin + '/#/' + url;
          }
        } else {
          href_url = window.location.origin;
        }
      }
      return href_url;
    },
    f_goToThisUrl: function (url) {
      if (url.indexOf('http') !== -1) {
        window.open(url, '_blank');
      } else {
        window.open(window.location.origin + '/#/' + url, '_blank');
      }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    f_wanalyzerMachinelearningDataGet: function (machine_learning_id) {
      let query = 'machine_learning_id=' + machine_learning_id;
      let data = {};
      WanalyzerService.wanalyzer_machinelearning_data_get(query, data)
        .then(resp => {
          if (resp.data.status_code === "50000") {
            this.d_machineLearningList[machine_learning_id] = resp.data.machine_learning;
            this.$forceUpdate();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    }
  },
  watch: {}
}

</script>

<style type="text/css">
.component_9 {
  height: 100%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 0px;
  border: none;
}

.component_9:hover {
  width: 101%;
  height: 101%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 2px;
  border: none;
  font-size: 18px;
  color: #20b2aa;
}

.component_10 {
  height: 100%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 0px;
  border: none;
}

.component_10:hover {
  width: 101%;
  height: 101%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 2px;
  border: none;
  font-size: 18px;
  color: #20b2aa;
}

.carousel-item img {
    min-height: 400px!important;
}

</style>

